import { guid } from '../../../server/helper'

// These enums must be synced with the enums in ABanner - SCSS section
export const BANNER_ALLOCATOR_PLACEHOLDER = {
  BANNER_SPACE_ALLOCATOR: 'guid-91f6dab1',
  MOBILE_MODIFIER: 'guid-3a604ac9',
  TABLET_MODIFIER: 'guid-0d37f0cb',
  DESKTOP_SM_MODIFIER: 'guid-e240ff1b',
  DESKTOP_SM2_MODIFIER: 'guid-c45921f2',
  DESKTOP_SM3_MODIFIER: 'guid-913ba782',
  DESKTOP_MD_MODIFIER: 'guid-ad89dea3',
  DESKTOP_LG_MODIFIER: 'guid-871eb4d3',
  DESKTOP_XL_MODIFIER: 'guid-f1b7f43e',
  ABOVE_DESKTOP_XL_MODIFIER: 'guid-284a6ccd'
}

export const BANNER_ALLOCATOR_PLACEHOLDER_BY_BREAKPOINT = {
  mobile: BANNER_ALLOCATOR_PLACEHOLDER.MOBILE_MODIFIER,
  tablet: BANNER_ALLOCATOR_PLACEHOLDER.TABLET_MODIFIER,
  desktopSm: BANNER_ALLOCATOR_PLACEHOLDER.DESKTOP_SM_MODIFIER,
  desktopSm2: BANNER_ALLOCATOR_PLACEHOLDER.DESKTOP_SM2_MODIFIER,
  desktopSm3: BANNER_ALLOCATOR_PLACEHOLDER.DESKTOP_SM3_MODIFIER,
  desktopMd: BANNER_ALLOCATOR_PLACEHOLDER.DESKTOP_MD_MODIFIER,
  desktopLg: BANNER_ALLOCATOR_PLACEHOLDER.DESKTOP_LG_MODIFIER,
  desktopXl: BANNER_ALLOCATOR_PLACEHOLDER.DESKTOP_XL_MODIFIER,
  aboveDesktopXl: BANNER_ALLOCATOR_PLACEHOLDER.ABOVE_DESKTOP_XL_MODIFIER
}

const BANNER_ALLOCATOR_DYNAMIC_VALUE_TEMPLATE = {
  [BANNER_ALLOCATOR_PLACEHOLDER.BANNER_SPACE_ALLOCATOR]: null,
  [BANNER_ALLOCATOR_PLACEHOLDER.MOBILE_MODIFIER]: null,
  [BANNER_ALLOCATOR_PLACEHOLDER.TABLET_MODIFIER]: null,
  [BANNER_ALLOCATOR_PLACEHOLDER.DESKTOP_SM_MODIFIER]: null,
  [BANNER_ALLOCATOR_PLACEHOLDER.DESKTOP_SM2_MODIFIER]: null,
  [BANNER_ALLOCATOR_PLACEHOLDER.DESKTOP_SM3_MODIFIER]: null,
  [BANNER_ALLOCATOR_PLACEHOLDER.DESKTOP_MD_MODIFIER]: null,
  [BANNER_ALLOCATOR_PLACEHOLDER.DESKTOP_LG_MODIFIER]: null,
  [BANNER_ALLOCATOR_PLACEHOLDER.DESKTOP_XL_MODIFIER]: null,
  [BANNER_ALLOCATOR_PLACEHOLDER.ABOVE_DESKTOP_XL_MODIFIER]: null
}

function generateUniqueId(existingIds = []) {
  let id
  do {
    id = `guid-${guid().match(/[\d\w]*/)[0]}`
  } while (existingIds.includes(id))

  return id
}

function fillDynamicValues(obj) {
  const usedIds = []
  const resultObject = {}

  for (const key in obj) {
    const uniqueId = generateUniqueId(usedIds)
    resultObject[key] = uniqueId
    usedIds.push(uniqueId)
  }

  return resultObject
}

export const BANNER_ALLOCATOR_DYNAMIC_VALUE_BY_PLACEHOLDER = fillDynamicValues(
  BANNER_ALLOCATOR_DYNAMIC_VALUE_TEMPLATE
)
